import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Utils
import { emailPattern, getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Footer from "@components/Footer.js";
import Input from "@components/input";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/logo.svg";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async ({ email }) => {
    setEmail(email);
    localStorage.setItem("email", email);
    navigate("/create-passkey", { state: { email } });
  };

  return (
    <div className="h-screen overflow-auto">
      <div className={getClassNames(" max-w-[1440px] mx-auto")}>
        <div className="container-auth  min-h-[52px] py-4 px-10 flex items-center justify-between">
          <PlatformLogo className="" />
          {/* <Button title="Log in" /> */}
        </div>
      </div>
      <div className="flex flex-col h-screen max-w-[1440px] mx-auto">
        <div
          className={getClassNames(
            "h-full w-full py-16 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <div className="max-w-[433px] h-full">
              <div className="bg-white rounded-lg p-8 w-full">
                <h3 className="text-[22px] mb-16">Create an account</h3>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-[378px] flex-1 h-full"
                >
                  <div className="flex flex-col gap-y-4 w-full">
                    <Input
                      name={"email"}
                      type={"email"}
                      label={"Email"}
                      category="auth"
                      {...{ register, errors }}
                      rules={{
                        required: true,
                        minLength: 6,
                        pattern: emailPattern,
                      }}
                      labelStyle="text-desc-grey"
                      placeholder={"name@company.com"}
                    />
                  </div>

                  <Button
                    title="Continue"
                    color="dark"
                    size="custom"
                    className="mt-6 w-full"
                    disabled={!isValid}
                    type="submit"
                  />
                </form>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
