import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";
import DemoGuidePopover from "@components/DemoGuidePopover";

// Components
import { Button } from "@components/button";
import { getClassNames, getScoreColorWithRisk } from "@utils/helpers";
import { authContext } from "@context/auth";

export const ShopperContactCard = ({ fullname, project_identifier }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) return navigate("/login");

  return (
    <div className="w-full flex flex-col min-w-[347px] pt-6 rounded-lg bg-grey-810 border border-grey-stroke-primary overflow-hidden">
      <div className="flex-1 px-8 pb-10 rounded-lg bg-grey-810">
        <h4 className="text-[2rem]">
          {user?.FirstName + " " + user?.LastName}
        </h4>
        <p className="max-w-[215px] mt-4 text-sm1 leading-[17px] text-grey-content-secondary">
          {user?.Address
            ? user.Address[0]?.StreetName
            : `50 Avenue des Pr'es, Montigny-le-bretonneux 78180, Île-de-France
          France`}
        </p>
      </div>
      <div className="flex items-center justify-between px-8 py-6 bg-white">
        <span className="text-[2rem] text-grey-content-tertiary">
          {project_identifier}
        </span>
        <div className="text-sm leading-4 text-body-black py-1 px-2 rounded bg-white border border-stroke-primary">
          Apollo Bank
        </div>
      </div>
    </div>
  );
};

export const ShopperTrustIndexInfoCard = ({ fullname, score }) => {

  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const { shopperData } = useContext(authContext);
  const { shopperTrustIndexPopUp, setShopperTrustIndexPopUp } = useContext(
    DemoGuidePopoverContext
  );
  let userData = null;
  if (!user) return navigate("/login");
  else userData = JSON.parse(user);

  return (
    <div className="w-full min-w-[347px] pt-6 rounded-lg bg-grey-810 border border-grey-stroke-primary overflow-hidden">
      <div className="px-8 pb-10 rounded-lg bg-grey-810">
        <h4 className="text-[22px]">Shopper Trust Index</h4>
        <p className="max-w-[354px] mt-4 text-base1 leading-[17px] text-grey-content-tertiary">
          Enjoy personalised experiences tailored to you like instant refunds
          and payment upon delivery.
        </p>
        <Button
          title="View"
          className="mt-10"
          onClick={() => navigate("/shopper-trust-index")}
        />
      </div>
      <div
        className="flex items-center justify-between px-8 py-3 bg-white"
        id="shopper-score"
      >
        <span className="text-[2rem] text-grey-content-tertiary">
          {userData?.FirstName + " " + userData?.LastName}
        </span>
        <div
          className={getClassNames(
            "flex items-center justify-center text-black text-[42px] bg-stroke rounded-full w-[74px] h-[74px]",
            getScoreColorWithRisk(shopperData?.risk_level)
          )}
        >
          {score}
        </div>
      </div>
      <DemoGuidePopover
        title="View Shopper Trust Index"
        body="Take a look at the consumer’s complete fraud profile, assessed through the lens of the console's configurations."
        el={() => document.getElementById("shopper-score")}
        topOffset={110}
        leftOffset={0}
        maxWidth="max-w-[380px]"
        arrowPosition="top"
        open={shopperTrustIndexPopUp}
        setOpen={setShopperTrustIndexPopUp}
      />
    </div>
  );
};
