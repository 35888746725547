import React from "react";

// Utils
import { terms } from "../data";

// Components
import { Button } from "@components/button";
import ConsentModal from "../components/consentModal";

const Consent = ({ showConsent, setShowConsent, step, setStep }) => {
  const bullets = [
    "Name",
    "Account name, number and sort code",
    "Account balances",
    "Details of your incoming transactions",
    "Details of your outgoing transactions",
  ];
  return (
    <>
      <ConsentModal {...{ showConsent, setShowConsent }} />
      <div className="w-full mt-2 rounded-lg py-8 px-4 lg:px-10 border border-grey-stroke-primary">
        <h6 className="header6 text-body-black">Open Banking Agreement</h6>
        <p className="mt-4 text-grey-content-secondary">
          We use Open Banking to calculate your Shopper Trust Index. <br />
          Shopper Trust Index helps us decide the shopping experience to provide
          to you based on your past ecommerce activity.
        </p>
        <p className="text-black text-sm1 mt-3">
          <button onClick={() => setShowConsent(true)}>
            <u>Learn more</u>
          </button>
        </p>
        <div className="mt-6 rounded-lg border border-grey-stroke-primary">
          <div className="paragraph1 text-body-black border-b border-b-stroke-primary py-4 px-4 lg:px-5">
            What we need
          </div>
          <div className="py-4 px-4 lg:px-5 bg-white2">
            <ul className="relative flex flex-col list-none">
              {bullets.map((bullet) => (
                <li
                  key={bullet}
                  className="bulleted py-4 border-b border-b-grey-10 text-grey-content-secondary"
                >
                  {bullet}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 mt-6">
          {terms.map((term) => (
            <div key={term.title}>
              <h6 className="paragraph1 text-body-black">{term.title}</h6>
              <p className="paragraph1 text-grey-content-secondary">
                {term.body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center gap-x-2 mt-6 py-5">
        <Button
          title="Cancel"
          category="1"
          color="light"
          className="w-fit"
          onClick={() => setStep(0)}
        />
        <Button
          title="I Agree"
          category="1"
          color="dark"
          className="w-fit"
          onClick={() => setStep(1)}
        />
      </div>
    </>
  );
};

export default Consent;
