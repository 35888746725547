import React from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";

const ConfigurationNav = ({ step, setStep, stage, setStage }) => {
  const steps = [
    {
      title: "Consent",
      stages: ["Accept agreement"],
    },
    {
      title: "Authenticate",
      stages: ["Select your Bank", "Authenticate via Bank"],
    },
    {
      title: "Authorize",
      stages: ["Select your accounts", "Authorize data sharing"],
    },
  ];

  return (
    <>
      <div className="hidden lg:flex flex-col gap-y-4 h-full text-grey-content-secondary py-8 pl-8 pr-4">
        {steps?.map((option, i) => (
          <div key={option.title}>
            <div className="flex items-center justify-between">
              <span className="">Step {i + 1}</span>
              <CaretIcon />
            </div>
            <div
              key={option.title}
              className={getClassNames(
                "relative flex flex-col gap-y-1 text-sm1 pb-10 mt-2 pl-3 border-l border-grey-stroke-primary"
              )}
            >
              <span className="text-grey-content-tertiary">{option.title}</span>
              <div className="absolute min-w-[6px] min-h-[6px] w-[6px] h-[6px] rounded-full top-0 -left-[0.215rem] bg-grey-stroke-primary" />
              {option.stages.map((stageTitle, j) => (
                <div className="relative" key={stageTitle}>
                  <div
                    className={getClassNames(
                      "absolute min-w-[6px] min-h-[6px] w-[6px] h-[6px] rounded-sm top-[50%] -left-4 bg-grey-content-secondary",
                      step === i && stage === j ? "block" : "hidden"
                    )}
                  />

                  <h6
                    className={getClassNames(
                      "hover:bg-grey-stroke-primary hover:text-black rounded px-3 py-2",
                      "cursor-pointer w-full mb-0.5",
                      step === i && stage === j
                        ? "text-black bg-grey-stroke-primary"
                        : ""
                    )}
                  >
                    {stageTitle}
                  </h6>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="lg:hidden flex items-center gap-x-4  justify-between py-6 max-w-[876px] mx-auto">
        {steps.map((stp, i) => (
          <div key={stp.title} className={getClassNames("flex items-center justify-center gap-x-2", i < 2 && "flex-1")}>
            <div
              className={getClassNames(
                "min-w-[9px] min-h-[9px]",
                i === step
                  ? "bg-black rounded-sm"
                  : "rounded-full bg-grey-content-quaternary"
              )}
            />
            <span>{stp.title}</span>
            {i >= 0 && i < 2 && (
              <hr className="text-grey-content-quaternary w-full"/>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ConfigurationNav;
