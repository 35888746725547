import React from "react";

// Components
// import { Button } from "@components/button";

// Assets
import { ReactComponent as MenuIcon } from "@icons/menu.svg";

const Header = ({ title }) => {
  return (
    <div className="border-b border-b-white2/10">
      <div className="container-centered flex items-center justify-between py-8">
        <a href="https://experiencemastercard.trudenty.com/home">
          <MenuIcon className="cursor-pointer" />
        </a>
        <h1 className="text-[2.5rem]">{title}</h1>
        <span></span>
        {/* <Button title='Contact us' onClick={() => navigate("/contact-us")} color=""
          className="text-xl text-white bg-[#6B6B6B80] py-3" /> */}
      </div>
    </div>
  );
};

export default Header;
