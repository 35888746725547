import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Utils
import { getClassNames, getInitials } from "@utils/helpers";

// Components
import RadioButton from "@components/RadioButton";
import { RiskTag } from "@pages/dashboard/components/shoppersCharts";
import ToolTip from "@components/ToolTip";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as OrdersIcon } from "@icons/order.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as CartIconLg } from "@icons/cart_lg.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet_lg.svg";
import { ReactComponent as PackageIcon } from "@icons/package.svg";
import { ReactComponent as BankIcon } from "@icons/bank.svg";
import { ReactComponent as AppleIcon } from "@icons/apple.svg";
import { ReactComponent as GoogleIcon } from "@icons/google-square.svg";
import { ReactComponent as DebitCardIcon } from "@icons/credit-card.svg";
import { ReactComponent as PayDeliveryIcon } from "@icons/pay_delivery.svg";
import { ReactComponent as PayInstallmentIcon } from "@icons/pay_installment.svg";

const Shop = () => {
  const navigate = useNavigate();
  const { addHistory, shopperData } =
    useContext(authContext);
  const [paymentMethod, setPaymentMethod] = useState(
    shopperData?.risk_level === "LOW_RISK_SHOPPER" ? "after" : "bank"
  );
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const handleSubmit = (method) => {
    switch (method) {
      case "after":
        navigate("/pay-after-delivery");
        break;
      case "bank":
        navigate("/pay-by-bank");
        break;
      default:
        navigate("/pay-by-bank");
    }
  };
  const user = localStorage.getItem("user");
  if (!user) return navigate("/login");
  return (
    <div className="bg-[#EFB6040D] h-screen overflow-auto">
      <div className="max-w-[933px] h-full mx-auto pt-8">
        <RetailCoLogo />
        <div className="h-full mt-7 grid grid-cols-[303px,1fr] gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-x-3 py-9 px-3 bg-white">
              <div className="w-[92px] h-[92px] bg-trud-black3 rounded-full text-white flex text-4xl items-center justify-center">
                {getInitials(
                  JSON.parse(user)?.FirstName + " " + JSON.parse(user)?.LastName
                )}
              </div>
              <div className="flex flex-col justify-center ">
                <span className="text-body-black leading-[0] mt-2 mb-2">Hi</span>
                <span className="text-blacked text-1xl">
                  {JSON.parse(user)?.FirstName}
                </span>
                <span className="bg-stroke p-2 rounded text-sm leading-4 text-grey-content-secondary">
                  {shopperData?.project_identifier}
                </span>
              </div>
            </div>
            <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm">
              <div className="flex bg-white items-center py-2 px-4 gap-x-3">
                <PersonOutline />
                <span className="">Profile</span>
              </div>
              <div className="bg-stroke-primary  flex  items-center py-2 px-4 gap-x-3">
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>
              <div className="hover:bg-stroke-primary flex  items-center py-2  px-4 gap-x-3">
                <OrdersIcon /> <span className="">Orders</span>
              </div>

              <div
                className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/returns")}
              >
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
              <div
                className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/wallet")}
              >
                <WalletIcon className="max-w-[24px]" />
                <span className="">Wallet</span>
              </div>
            </div>
          </div>
          <div className="bg-white py-8 px-4">
            <div className="flex items-center justify-between">
              <div>
                <CartIconLg />
                <span className="text-blacked text-lg mt-4 leading-[150%]">
                  SHOP
                </span>
              </div>
              {/* <RiskTag risk="trusted" showInfo /> */}
            </div>
            <div className="mt-8 h-3 w-full bg-stroke animate-pulse" />

            <div className="flex items-center py-3 border-b border-stroke">
              <div className="py-6 px-8 bg-stroke">
                <PackageIcon />
              </div>
              <div className="w-1/3 ml-8 max-w-[167px]">
                <div className="h-3 w-full bg-stroke animate-pulse" />
                <div className="h-3 mt-2 w-2/3 bg-stroke animate-pulse" />
              </div>
            </div>
            {showPaymentOptions && (
              <div className="mt-8">
                <p className="text-sm text-grey-content-secondary">
                  Select payment method
                </p>
                <div className="mt-4 flex flex-col gap-y-4">
                  {shopperData?.available_payment_methods &&
                  shopperData?.available_payment_methods[
                    "Pay after delivery"
                  ] === true ? (
                    <div
                      className={getClassNames(
                        "flex items-center justify-between cursor-pointer"
                      )}
                      onClick={() => setPaymentMethod("after")}
                    >
                      <div className="flex items-center gap-x-2">
                        <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                          <PayDeliveryIcon />
                        </div>
                        <span className="text-sm text-body-black">
                          Pay after delivery
                        </span>
                      </div>
                      <RadioButton active={paymentMethod === "after"} />
                    </div>
                  ) : null}
                  {shopperData?.available_payment_methods &&
                  shopperData?.available_payment_methods[
                    "Pay by installments"
                  ] === true ? (
                    <div
                      className={getClassNames(
                        "flex items-center justify-between cursor-pointer opacity-50"
                      )}
                      >
                      <div className="flex items-center gap-x-2">
                        <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                          <PayInstallmentIcon />
                        </div>
                        <span className="text-sm text-body-black">
                        Pay by installments
                        </span>
                      </div>
                      <RadioButton active={paymentMethod === "installment"}/>
                    </div>
                  ) : null}
                
                  <div
                    className="flex items-center justify-between cursor-pointer opacity-50"
                    // onClick={() => setPaymentMethod("card")}
                  >
                    <div className="flex items-center gap-x-2">
                      <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                        <DebitCardIcon />
                      </div>
                      <span className="text-sm text-body-black">
                        Debit/Credit card
                      </span>
                    </div>
                    <RadioButton active={paymentMethod === "card"} />
                  </div>
                  {shopperData?.available_payment_methods &&
                    shopperData?.available_payment_methods["Pay by Bank"] ===
                      true && (
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setPaymentMethod("bank")}
                      >
                        <div className="flex items-center gap-x-2">
                          <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                            <BankIcon />
                          </div>
                          <span className="text-sm text-body-black">
                            Pay by Bank
                          </span>
                        </div>
                        <RadioButton active={paymentMethod === "bank"} />
                      </div>
                    )}
                  <div
                    className="flex items-center justify-between cursor-pointer opacity-50"
                    // onClick={() => setPaymentMethod("apple")}
                  >
                    <div className="flex items-center gap-x-2">
                      <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                        <AppleIcon />
                      </div>
                      <span className="text-sm text-body-black">Apple Pay</span>
                    </div>
                    <RadioButton active={paymentMethod === "apple"} />
                  </div>
                  <div
                    className="flex items-center justify-between cursor-pointer opacity-50"
                    // onClick={() => setPaymentMethod("google")}
                  >
                    <div className="flex items-center gap-x-2">
                      <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                        <GoogleIcon />
                      </div>
                      <span className="text-sm text-body-black">
                        Google Pay
                      </span>
                    </div>
                    <RadioButton active={paymentMethod === "google"} />
                  </div>
                </div>
                <div className="relative group w-full max-w-[404px] mx-auto mt-9">
                  <button
                    className="w-full max-w-[404px] bg-body-black py-3 px-8 text-white cursor-pointer h-fit"
                    onClick={() => {
                      addHistory(
                        "Checkout - Shopper Trust Index credential has been read"
                      );
                      // navigate("/dashboard/1/wallet");
                      handleSubmit(paymentMethod);
                    }}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            )}
            {!showPaymentOptions && (
              <div className="relative group w-full max-w-[404px] mx-auto mt-9">
                <button
                  className="w-full max-w-[404px] bg-body-black py-3 px-8 text-white cursor-pointer h-fit"
                  onClick={() => setShowPaymentOptions(true)}
                >
                  CHECKOUT
                </button>
                <ToolTip
                  className="max-w-[283px] top-14 left-0 right-0 mx-auto"
                  info="You have given consent to Retail Co to read your Shopper Trust Index for the next 180 days. "
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
