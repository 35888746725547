import React from "react";
import { getClassNames } from "@utils/helpers";

const IButton = ({
  title,
  size = "small",
  color = "primary",
  className = "",
  onClick,
  disabled = false,
  type,
  loading,
}) => {
  const colors = {
    primary: "bg-black text-grey",
    white: "bg-grey text-body-black",
    pure: "bg-white text-black",
    custom: "",
  };
  const sizeClass = {
    thin: "text-base px-4 py-2 max-w-fit rounded-3xl",
    small: "text-base px-8 py-2 max-w-fit rounded-3xl",
    mid: "text-xl px-7 py-4 rounded-3xl",
    custom: `${className}`,
  };

  return (
    <button
      tabIndex={disabled ? -1 : 0}
      onClick={disabled ? () => {} : onClick}
      className={getClassNames(
        `whitespace-nowrap flex items-center justify-center gap-x-1 min-w-fit leading-5 font-semibold ${
          sizeClass[size]
        } ${colors[color]} ${disabled ? "cursor-not-allowed" : ""} ${
          loading ? "cursor-wait" : ""
        }`
      )}
    >
      <span className="-tracking-[0.2px]">{title}</span>
    </button>
  );
};

export const Button = ({
  title,
  category = "1",
  color = "light",
  className = "",
  onClick,
  disabled = false,
  type,
  loading,
  prefixIcon,
  suffixIcon,
  ref,
}) => {
  const categoryType = {
    1: "py-2 px-4 rounded-[27px]",
    2: "py-1 px-3 rounded leading-5",
    3: "py-2 px-6 w-full rounded-[27px]",
  };
  const scheme = {
    light: disabled
      ? "bg-grey-850 text-placeholder-grey border-0"
      : "text-black bg-white border border-grey-200",
    dark: disabled
      ? "bg-grey-850 text-placeholder-grey border-0"
      : "text-grey bg-btn-primary border border-primary",
    dark2: disabled
      ? "bg-disabled-black text-disabled-white border-0"
      : "text-grey bg-btn-primary border border-primary",
    grey: disabled
      ? "bg-white text-grey-content-secondary"
      : "bg-grey-stroke-primary text-black",
  };

  return (
    <button
      ref={ref}
      type={type || "button"}
      tabIndex={disabled ? -1 : 0}
      onClick={disabled ? () => {} : onClick}
      className={getClassNames(
        "whitespace-nowrap flex items-center justify-center gap-x-1 min-w-fit h-fit font-semibold",
        categoryType[category],
        scheme[color],
        className,
        disabled ? "cursor-not-allowed" : "",
        loading ? "cursor-wait" : ""
      )}
    >
      {loading && <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>}
      {prefixIcon}
      <span className="-tracking-[0.2px]">{title}</span>
      {suffixIcon}
    </button>
  );
};

export default IButton;
