import { createContext, useState } from "react";

export const DemoGuidePopoverContext = createContext({
  loginPopUp: false,
  setLoginPopUp: () => {},
  shopperTrustIndexPopUp: false,
  setShopperTrustIndexPopUp: () => {},
});

const DemoGuidePopoverContextProvider = ({ children }) => {
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [shopperTrustIndexPopUp, setShopperTrustIndexPopUp] = useState(false);

  return (
    <DemoGuidePopoverContext.Provider
      value={{
        loginPopUp,
        setLoginPopUp,
        shopperTrustIndexPopUp,
        setShopperTrustIndexPopUp,
      }}
    >
      {children}
    </DemoGuidePopoverContext.Provider>
  );
};

export default DemoGuidePopoverContextProvider;
