import React, { useState } from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import ConfigurationNav from "./components/configurationNav";
import Consent from "./components/consent";
import Topbar from "@components/topbar";
import Authenticate from "./components/authenticate";

const ShopperConfiguration = () => {
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);
  const [showConsent, setShowConsent] = useState(false);

  const stepsData = [
    {
      stepTitle: "Consent",
      title: "Consent to Open Banking data sharing",
    },
    {
      stepTitle: "Authenticate",
      title: "Select your Bank",
      description: `Select the bank with which you hold your accounts to provide the most
        comprehensive view of your transactional history to calculate your Shopper Trust Index`,
    },
    {
      stepTitle: "Authorize",
      title: "Authorize",
      description: `Select the bank with which you hold your accounts to provide the most
        comprehensive view of your transactional history to calculate your Shopper Trust Index`,
    },
  ];

  return (
    <div className="overflow-hidden max-h-screen">
      <div className="min-h-[62px]">
      <Topbar title="Set up my Shopper Trust Index" />
      </div>
      <div
        className={getClassNames(
          "w-full max-h-full h-full flex flex-col  container-solutions  overflow-hidden",
          showConsent && "blur-sm"
        )}
      >
        <div className="lg:grid grid-cols-[285px,1fr] flex-1 gap-x-6 h-full">
          <ConfigurationNav {...{ step, setStep, stage, setStage }} />
          <div className="max-w-7xl h-full max-h-[95vh]  overflow-x-hidden">
            <div
              className={getClassNames(
                "max-w-[876px] mx-auto lg:pt-8 pb-6 flex flex-col h-full max-h-full",
                "text-grey-content-tertiary"
              )}
            >
              <div className="hidden lg:flex items-center gap-x-4">
                <div className="text-grey-content-secondary rounded-3xl bg-grey-stroke-primary px-2 py-0.5">
                  Step {step + 1}
                </div>
                <span className="text-lg">{stepsData[step]["stepTitle"]}</span>
              </div>
              <div className="flex justify-between">
                <div className="max-w-[631px]">
                  <h2 className="header2 lg:mt-6 text-trud-black">
                    {stepsData[step]["title"]}
                  </h2>
                  <p className="text-base1 leading-xl1 mt-2 text-grey-content-tertiary">
                    {stepsData[step]["description"]}
                  </p>
                </div>
              </div>
              <div className="h-10 md:h-8" />
              <div className="flex-1 max-h-full overflow-auto">
                {step === 0 && (
                  <Consent
                    {...{ step, setStep, showConsent, setShowConsent }}
                  />
                )}
                {step === 1 && <Authenticate {...{ setStep }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperConfiguration;
