import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Utils
import { emailPattern, getClassNames } from "@utils/helpers";
import { axiosInstance } from "@utils/baseAxios.js";

// Components
import { Button } from "@components/button";
import Footer from "./components/Footer.js";
import Input from "@components/input";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/trudenty-logo.svg";
import IncorrectInputError from "./components/IncorrectInputError.js";

const SignUpWithTrudenty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const isEnrollment = pathname === "/enrollment";

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async ({ email }) => {
    await axiosInstance
      .post("/send-invite-email", { email })
      .then(() => {
        navigate("/protect/signup-success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex flex-col h-full bg-white3">
      <div className={getClassNames("bg-[#22222205]")}>
        <div className="max-w-6xl  mx-auto min-h-[52px] flex items-center justify-end relative">
          <PlatformLogo className="absolute left-0" />
          <Button
            title="Log in"
            onClick={() => navigate("/protect/login")}
            className="lg:py-1"
          />
        </div>
      </div>
      <div className="h-full overflow-scroll">
        <div
          className={getClassNames(
            "h-full w-full py-16 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <div className="max-w-[378px]">
              <h3 className="text-body-black text-xl2 mb-10 leading-[120%]">
                Create a Trudenty account
              </h3>

              <form onSubmit={handleSubmit(onSubmit)} className="w-[378px]">
                <div className="flex flex-col gap-y-4 w-full">
                  <Input
                    name={"email"}
                    type={"email"}
                    label={"Business Email"}
                    category="auth"
                    {...{ register, errors }}
                    rules={{
                      required: true,
                      minLength: 6,
                      pattern: emailPattern,
                    }}
                    labelStyle="text-desc-grey"
                    placeholder={"name@company.com"}
                  />
                </div>

                <IncorrectInputError
                  show={isEnrollment}
                  message="The invitation period for this email address has expired. Please contact your company administrator."
                />

                <Button
                  title="Continue"
                  color="dark"
                  size="custom"
                  className="mt-6 w-full"
                  disabled={!isValid}
                  type="submit"
                />

                <p className="mt-8">
                  <span className="text-grey-content-secondary">
                    Already signed up?{" "}
                  </span>{" "}
                  <span
                    onClick={() => navigate("/protect/signup")}
                    className="cursor-pointer text-body-black underline"
                  >
                    Log in
                  </span>
                </p>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignUpWithTrudenty;
