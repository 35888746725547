import React from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";
import { ReactComponent as ShopperScore } from "@images/shopper-trust-score.svg";
import { Button } from "@components/button";

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen overflow-auto p-10">
      <div
        className={getClassNames(
          "relative container-solutions max-w-[800px] h-full md:h-fit flex flex-col items-center justify-center",
          "overflow-hidden md:rounded-2xl border border-stroke-primary px-4 pb-52 min-h-[776px] md:min-h-[auto]",
          "bg-white2"
        )}
      >
        <div
          className="absolute rounded-full p-3 flex items-center justify-center bg-stroke cursor-pointer right-4 md:right-8 top-8"
          onClick={() => navigate("/")}
        >
          <CloseIcon className="w-3 h-3" />
        </div>
        <h5 className="text-center text-base1 mt-10 mb-4 text-grey-content-secondary">
          Get started
        </h5>
        <div className="max-w-[510px] flex flex-col items-center">
          <h1 className="header1 text-center  mt-10 mb-4 max-w-[422px] leading-[100%]">
            Benefit from Instant Refunds
          </h1>
          <p className="text-center text-grey-content-secondary text-base1">
            The Shopper Trust Index enables us to provide you with exclusive
            experiences tailored to your shopping habits, confirming your status
            as a trusted shopper. <br />
            <br />
            Enjoy the privilege of instant refunds and payment upon delivery, a
            benefit reserved exclusively for our trusted shoppers.
          </p>
          <Button
            title="Continue"
            color="dark"
            className="w-full max-w-[422px] mt-11"
            onClick={() => navigate("/setup")}
          />
          <span className="text-sm text-grey-content-tertiary mt-4">
            Powered by Trudenty
          </span>
          <ShopperScore className="absolute -bottom-2" />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
