import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as PersonOutlineMd } from "@icons/person_outlined_md.svg";
import { ReactComponent as OrdersIcon } from "@icons/order.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet_lg.svg";
import { ReactComponent as PackageIcon } from "@icons/package.svg";

const Profile = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  return (
    <div className="h-screen">
      <div className="max-w-[933px] h-full mx-auto pt-8">
        <RetailCoLogo />
        <div className="h-full mt-7 grid grid-cols-[303px,1fr] gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-x-3 py-9 px-3 bg-white">
              <div className="w-[92px] h-[92px] bg-trud-black3 animate-pulse rounded-full text-white flex text-4xl items-center justify-center">
                
              </div>
              <div className="flex flex-col justify-center ">
                <span className="text-body-black leading-[0] mt-2 mb-3">Hi</span>
                <span className="text-blacked text-xs my-2">
                  {email}
                </span>
                <span className="bg-stroke p-2 rounded text-sm leading-4 text-grey-content-secondary animate-pulse">
                  
                </span>
              </div>
            </div>
            <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm text-content-primary">
              <div className="flex bg-stroke-primary items-center py-2 px-4 gap-x-3">
                <PersonOutline />
                <span className="">Profile</span>
              </div>
              <div className="flex items-center hover:bg-stroke-primary py-2 px-4 gap-x-3">
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>
              <div className="flex  items-center hover:bg-stroke-primary py-2  px-4 gap-x-3">
                <OrdersIcon /> <span className="">Orders</span>
              </div>

              <div className="flex hover:bg-stroke-primary items-center py-2 px-4 gap-x-3">
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
              <div className="flex hover:bg-stroke-primary items-center py-2 px-4 gap-x-3">
                <WalletIcon className="max-w-[24px]" />
                <span className="">Wallet</span>
              </div>
              
            </div>
          </div>
          <div className="bg-white py-8 px-4">
            <PersonOutlineMd />
            <h3 className="text-blacked text-lg mt-3 leading-[150%] font-inter font-bold">
              PROFILE
            </h3>
            <div className="flex items-center gap-x-4 mt-12">
              <div className="w-[75px] h-[75px] bg-trud-black3 rounded-full animate-pulse text-white flex text-[2rem] items-center justify-center">
                
              </div>
              <div className="h-8 w-20 bg-stroke animate-pulse" />
            </div>
            <div className="flex gap-x-4 mt-8">
              <div className="h-12 w-full bg-stroke animate-pulse" />
              <div className="h-12 w-full bg-stroke animate-pulse" />
            </div>
            <div className="mt-2 h-12 w-full bg-stroke animate-pulse" />

            <div className="mt-7 p-6 border border-stroke-primary rounded">
              <h5 className="text-lg text-blacked font-inter font-bold">
                Become a Trusted Shopper
              </h5>
              <p className="text-grey-content-secondary text-base1 mt-2 max-w-[466px]">
                Enjoy personalised experiences tailored to you like instant
                refunds and payment upon delivery. To provide you with these
                amazing benefits, we need to calculate your shopper trust index
                and generate your shopper identity credential. We do this
                through a simple 3-step open banking process with your bank.
              </p>

              <button
                className="w-full bg-trud-black2 py-3 px-8 mt-6 text-white cursor-pointer h-fit"
                onClick={() => navigate("/get-started")}
              >
                GET STARTED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
