import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "@utils/baseAxios";
import { getInitials } from "@utils/helpers";

// Context
import { authContext } from "@context/auth";

// Components
import Topbar from "@components/topbar";
import {
  ShopperContactCard,
  ShopperTrustIndexInfoCard,
} from "@pages/dashboard/components/shopperDetailsCard";
import HistoryTable from "./historytable";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as OrdersIcon } from "@icons/order.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet_lg.svg";

const Wallet = () => {
  const {
    shopperData,
    setShopperData,
    shopperScore,
    setShopperScore,
  } = useContext(authContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [fullName, setFullName] = useState();
  const user = localStorage.getItem("user");
  useEffect(() => {
    const getData = async () => {
      if (!user) return navigate("/login");
      const userId = JSON.parse(user)?.ProfileId;
      const FirstName = JSON.parse(user)?.FirstName;
      const LastName = JSON.parse(user)?.LastName;
      const fullName = String(FirstName + " " + LastName);

      axiosInstance
        .get(`/trusted_shoppers/${userId}?mastercard_user_name=${fullName}`)
        .then(async (response) => {
          const score = response?.data?.attributes?.reduce(
            (accumulator, current) => {
              return accumulator + parseInt(current?.score);
            },
            0
          );
          setFullName(
            JSON.parse(user)?.FirstName + " " + JSON.parse(user)?.LastName
          );
          setShopperScore(score);
          setShopperData(response?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getData();
  }, [pathname]);

  return (
    <div className="bg-[#EFB6040D] h-screen overflow-auto px-10 min-w-[1248px]">
      <div className="max-w-[1440px] h-full mx-auto pt-8">
        <RetailCoLogo />
        <div className="h-full mt-7 grid grid-cols-[303px,1fr] gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-x-3 py-9 px-3 bg-white">
              <div className="w-[92px] h-[92px] bg-trud-black3 rounded-full text-white flex text-4xl items-center justify-center">
                {getInitials(fullName)}
              </div>
              <div className="flex flex-col justify-center ">
                <span className="text-body-black leading-[0] mt-2 mb-2">Hi</span>
                <span className="text-blacked text-1xl">
                  {JSON.parse(user)?.FirstName}
                </span>
                <span className="bg-stroke p-2 rounded text-sm leading-4 text-grey-content-secondary">
                  {shopperData?.project_identifier}
                </span>
              </div>
            </div>
            <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm text-content-primary">
              <div className="flex hover:bg-stroke-primary items-center py-2 px-4 gap-x-3">
                <PersonOutline />
                <span className="">Profile</span>
              </div>
              <div
                className="hover:bg-stroke-primary  flex  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/shop")}
              >
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>
              <div className="hover:bg-stroke-primary  flex  items-center py-2  px-4 gap-x-3">
                <OrdersIcon /> <span className="">Orders</span>
              </div>

              <div
                className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/returns")}
              >
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
              <div className="flex bg-stroke-primary  items-center py-2 px-4 gap-x-3">
                <WalletIcon className="max-w-[24px]" />
                <span className="">Wallet</span>
              </div>
              <div
                className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3 cursor-pointer"
                onClick={() => navigate("/")}
              >
               <span />
                <span className="">Switch consumer profile</span>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <Topbar title="Wallet" showBackButton={false} />
            <div className="py-6 px-8">
              <h5 className="text-body-black">Credentials</h5>
              <div className="grid grid-cols-2 gap-x-4">
                <ShopperTrustIndexInfoCard
                  score={shopperScore}
                  fullname={shopperData?.fullname}
                />
                <ShopperContactCard
                  fullname={shopperData?.fullname}
                  project_identifier={shopperData?.project_identifier}
                />
              </div>
              <h4 className="text-lg text-body-black mt-16">History</h4>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
