import React, { useState } from "react";

// Hooks
import useScreenSize from "@hooks/useScreenSize";

// Helpers
import { getClassNames } from "@utils/helpers";

const CustomHistogram = ({ score }) => {
  const { width } = useScreenSize();

  const data = [
    { label: "SEP", score: score ? score : 0 },
    { label: "AUG", score: 0 },
    { label: "JUL", score: 0 },
    { label: "JUN", score: 0 },
    { label: "MAY", score: 0 },
    { label: "APR", score: 0 },
  ].slice(0, width < 450 ? 6 : 8);

  const [animate, setAnimate] = useState(false);

  setTimeout(() => {
    setAnimate(true);
  }, 100);

  return (
    <div className="h-[159px] pb-10 md:h-full max-h-[90%] w-full flex gap-x-0.5 ml-auto justify-end items-end">
      {data.map((item) => (
        <div
          className={getClassNames(
            "relative bg-grey-stroke-primary w-[55px] h-0 transition-all duration-500",
            "rounded flex flex-col items-center pt-2 hover:opacity-70"
          )}
          key={item.label}
          style={{ height: animate ? `${item.score}%` : 0 }}
        >
          <span
            className={getClassNames(
              "rounded-full w-5 h-5 bg-igrey-15 p-1 mx-auto text-grey-content-secondary pt-[6px]",
              "flex items-center justify-center text-sm opacity-0 transition-opacity duration-700",
              animate ? "opacity-70" : "opacity-0",
              item.score < 40 && "hidden"
            )}
          >
            {item.score}
          </span>
          <span className="absolute -bottom-6 left-0 text-xs text-grey-content-tertiary">
            {item.label}
          </span>
          <span
            className={getClassNames(
              "absolute -bottom-5 right-1 text-[48px] text-[#E3E3E3]",
              "overflow-hidden max-w-[40px] opacity-0 transition-opacity duration-700",
              animate ? "opacity-100" : "opacity-0",
              item.score < 40 && "hidden"
            )}
          >
            {item.score}
          </span>
        </div>
      ))}
    </div>
  );
};

export default CustomHistogram;
