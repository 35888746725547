import React from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Footer from "./components/Footer.js";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/trudenty-logo.svg";
import { ReactComponent as EmailIllustration } from "@icons/illustration-email.svg";

const SignUpSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full bg-white3">
      <div className={getClassNames("bg-[#22222205]")}>
        <div className="max-w-6xl  mx-auto min-h-[52px] flex items-center justify-end relative">
          <PlatformLogo className="absolute left-0" />
          <Button
            title="Log in"
            onClick={() => navigate("/protect/login")}
            className="lg:py-1"
          />
        </div>
      </div>
      <div className="h-full overflow-scroll">
        <div
          className={getClassNames(
            "h-full w-full pt-24 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <EmailIllustration />
            <h4 className="text-body-black header4 mt-12">
              Thank you for signing up for the Trudenty demo
            </h4>
            <p className="text-desc-grey mt-3">
              Please check your email for next steps.
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccess;
