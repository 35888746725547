import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Utils
import { addDays, getInitials } from "@utils/helpers";
import { axiosInstance } from "@utils/baseAxios";

// Components
import { RiskTag } from "@pages/dashboard/components/shoppersCharts";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as OrdersIcon } from "@icons/order.svg";
import { ReactComponent as PackageIcon } from "@icons/package.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet_lg.svg";
import ToolTip from "@components/ToolTip";

const Returns = () => {
  const navigate = useNavigate();
  const { addHistory, shopperData } = useContext(authContext);
  const onReturn = async () => {
    addHistory(
      "Retail Co - Shopper Trust Index credential has been read",
      true
    );
    
    const token = JSON.parse(localStorage.getItem("tokens"));
    await axiosInstance
      .post("/initiate_mastercard_transaction", {
        to: shopperData?.account_num,
        from: "1574133678",
        authorization: token?.accessToken,
        token_id: token?.tokenId,
        instructed_amount: 85,
        currency: "USD",
      })
      .then((response) => {
        navigate("qr-scan");
      })
      .catch((err) => {
        console.log(err);
      });
      navigate("qr-scan");
  };
  const user = localStorage.getItem("user");
  if (!user) return navigate("/login");
  return (
    <div className="bg-[#EFB6040D] h-screen overflow-hidden">
      <div className="max-w-[933px] h-full mx-auto pt-8">
        <RetailCoLogo />
        <div className="h-full mt-7 grid grid-cols-[303px,1fr] gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex gap-x-3 py-9 px-3 bg-white">
              <div className="w-[92px] h-[92px] bg-trud-black3 rounded-full text-white flex text-4xl items-center justify-center">
                {getInitials(
                  JSON.parse(user)?.FirstName + " " + JSON.parse(user)?.LastName
                )}
              </div>
              <div className="flex flex-col justify-center ">
                <span className="text-body-black leading-[0] mt-2 mb-2">Hi</span>
                <span className="text-blacked text-1xl">
                  {JSON.parse(user)?.FirstName}
                </span>
                <span className="bg-stroke p-2 rounded text-sm leading-4 text-grey-content-secondary">
                  {shopperData?.project_identifier}
                </span>
              </div>
            </div>
            <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm">
              <div className="hover:bg-stroke-primary flex bg-white items-center py-2 px-4 gap-x-3">
                <PersonOutline />
                <span className="">Profile</span>
              </div>
              <div
                className="hover:bg-stroke-primary flex  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/shop")}
              >
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>
              <div className="hover:bg-stroke-primary flex  items-center py-2  px-4 gap-x-3">
                <OrdersIcon /> <span className="">Orders</span>
              </div>

              <div className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3">
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
              <div
                className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3"
                onClick={() => navigate("/dashboard/1/wallet")}
              >
                <WalletIcon className="max-w-[24px]" />
                <span className="">Wallet</span>
              </div>
            </div>
          </div>
          <div className="bg-white py-8 px-4">
            <div className="flex items-center justify-between">
              <div>
                <ReturnsIcon />
                <span className="text-blacked text-lg mt-4 leading-[150%]">
                  RETURNS
                </span>
              </div>
              {/* <RiskTag risk="trusted" showInfo /> */}
            </div>
            <span className="text-blacked text-lg mt-4 leading-[150%]"></span>
            <div className="mt-8 h-3 w-full bg-stroke animate-pulse" />

            <div className="flex items-center py-3">
              <div className="py-6 px-8 bg-stroke">
                <PackageIcon />
              </div>
              <div className="ml-8 min-w-[266px]">
                <div className="h-3 w-full bg-stroke animate-pulse max-w-[167px]" />
                <div className="h-3 mt-2 w-2/3 bg-stroke animate-pulse max-w-[121px]" />
              </div>
              <div className="relative group ml-auto">
                <button
                  className="bg-body-black py-3 px-8 text-white cursor-pointer h-fit"
                  onClick={onReturn}
                >
                  RETURN
                </button>
                <ToolTip
                  className="min-w-[283px] top-10 right-0"
                  info="You have given consent to Retail Co to read your Shopper Trust Index for the next 180 days. "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Returns;
