import { createContext, useState, useEffect } from "react";
import { getRandomInt } from "@utils/helpers";

export const authContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasCookie, setHasCookie] = useState(true);
  const [email, setEmail] = useState();
  const [hasSetup, setHasSetup] = useState(false);
  const [randNum, setRandNum] = useState();

  const [shopperData, setShopperData] = useState()
  const [shopperScore, setShopperScore] = useState()
  const [trustedShopperScore, setTrustedShopperScore] = useState()


  const [historyData, setHistoryData] = useState([
    {
      body: "Calculated and shared",
      action: "Shopper Trust Index",
      sub: "credential",
      time: new Date(),
      type: 0,
    },
    {
      body: "Open Banking consent - expires in",
      action: "180 days (29 April, 2024)",
      sub: "",
      time: new Date(),
      type: 0,
    },
  ]);
  const addHistory = (body, isReturn = false) => {
    let history = [...historyData];
    history.push({
      body,
      time: new Date(),
      type: 1,
    });
    if (isReturn) {
      history.push({
        body: "Return initiated - Shopper Trust Index credential has been read",
        time: new Date(),
        type: 1,
      });
    }
    history.sort((a, b) => b.time - a.time);
    setHistoryData([...history]);
  };

  useEffect(() => {
    setRandNum(getRandomInt(4));
  }, []);

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        randNum,
        hasSetup,
        setHasSetup,
        historyData,
        addHistory,
        email,
        setEmail,
        hasCookie,
        setHasCookie,
        shopperData,
        setShopperData,
        shopperScore,
        setShopperScore,
        trustedShopperScore,
        setTrustedShopperScore,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
