import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "@icons/close-white.svg";
import { getClassNames } from "@utils/helpers";

const ConfigChangesPopover = () => {
  const [showPopover, setShowPopover] = useState(true);
  const [check, setCheck] = useState(false);
  const email = localStorage.getItem("email");
  const isPopUpDisable = localStorage.getItem("popUpDisabled");

  const disablePopup = () => {
    setShowPopover(false);
    if (check) {
      localStorage.setItem("popUpDisabled", String(true));
    }
  };

  return email && !isPopUpDisable && showPopover ? (
    <div className="absolute top-6 right-10 p-6 bg-body-black rounded-lg z-10">
      <div className="flex items-center justify-between">
        <h6 className="header6 text-white">Configuration changes</h6>
        <CloseIcon
          className="hover:scale-95 cursor-pointer"
          onClick={() => disablePopup()}
        />
      </div>
      <p className="text-grey-content-tertiary max-w-[302px] mt-2 leading-[120%]">
        If you’ve redefined who a Trusted, Medium and High risk shopper is, look
        out for the changes in the 3 shopper experiences below. <br /> <br />
        If you’ve changed the checkout and refund shopper experiences for any
        segment look out for the changes in{" "}
        <span className="text-white2">Shop</span> and{" "}
        <span className="text-white2">Returns</span>
      </p>
      <div className="mt-4 flex items-center gap-x-1">
        <div
          className={getClassNames(
            "rounded-sm w-3 h-3 bg-[#E7E7E7] cursor-pointer"
          )}
          onClick={() => setCheck((prev) => !prev)}
        >
          <div
            className={getClassNames(
              check ? "bg-body-black border border-[#E7E7E7] rounded-sm" : "",
              "w-full h-full"
            )}
          ></div>
        </div>
        <span className="text-grey-content-quaternary">Don’t show again</span>
      </div>
    </div>
  ) : null;
};

export default ConfigChangesPopover;
