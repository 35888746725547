import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";

// Helpers
import { getClassNames, emailPattern } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";

const CreateWalletModal = ({ show, setShow }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const onSubmit = async () => navigate("/dashboard/1/wallet");
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[418px] transform rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all py-8 px-6 mx-1 md:mx-0`
                )}
              >
                <div className="flex items-center justify-between">
                  <h4 className="header4 text-body-black">
                    Create your wallet
                  </h4>
                  <CloseIcon
                    onClick={() => setShow(false)}
                    className="cursor-pointer"
                  />
                </div>
                <p className="mt-2 text-grey-content-secondary leading-[120%]">
                  To make sharing easier in the future, setup a Trudenty wallet
                  to save your Shopper Trust Index.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
                  <Input
                    label="Email"
                    placeholder="name@email.com"
                    name="email"
                    {...{ register }}
                    rules={{
                      required: true,
                      minLength: 6,
                      pattern: emailPattern,
                    }}
                  />

                  <p className="mt-8 text-grey-content-tertiary paragraph1 p-2 px-6 bg-grey-10 border border-stroke-primary rounded">
                    By proceeding, you consent to our privacy policy and agree
                    to our terms and conditions, including the sharing of
                    specific information as outlined in our privacy policy.
                  </p>

                  <Button
                    title="Continue"
                    category="1"
                    color="dark"
                    disabled={!isValid}
                    className={getClassNames("mt-8 w-full")}
                    type="submit"
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateWalletModal;
