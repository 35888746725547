import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Utils
import { getClassNames } from "@utils/helpers";
import { axiosInstance } from "@utils/baseAxios";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as ApolloBankIcon } from "@icons/apollo_bank.svg";
import { ReactComponent as RetailCoIcon } from "@icons/retail_co_logo.svg";
import { authContext } from "@context/auth";

const PaymentAuthorization = () => {
  const { shopperData } = useContext(authContext);
  const navigate = useNavigate();

  const {
    formState: {},
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onApprove = () => {
    const token = JSON.parse(localStorage.getItem("tokens"));
    axiosInstance
      .post("/initiate_mastercard_transaction", {
        from: shopperData?.account_num,
        to: "1574133678",
        authorization: token?.accessToken,
        token_id: token?.tokenId,
        instructed_amount: 85,
        currency: "USD",
      })
      .then((response) => {
        navigate("/order-confirmation");
      })
      .catch((err) => {
        console.log(err);
      });
    navigate("/order-confirmation");
  };

  return (
    <div className="bg-white">
      <div className="flex flex-col h-full min-h-screen bg-[#0F30471A] w-full mx-auto">
        <div className={getClassNames("")}>
          <div className="container mx-auto min-h-[52px] relative flex flex-col justify-center ">
            <ApolloBankIcon className="" />
          </div>
        </div>
        <div className="h-full overflow-auto">
          <div
            className={getClassNames(
              "h-full w-full py-16 mx-auto container-auth",
              "flex-1 flex flex-col h-full rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto flex-1 mb-10",
                "flex flex-col items-center"
              )}
            >
              <div className="min-w-[459px] p-8 bg-white border border-stroke-primary rounded-lg">
                <div className="rounded-lg">
                  <h2 className="header2 text-body-black">
                    Payment authorization
                  </h2>
                  <div className="mt-8">
                    <div className="h-3 w-full bg-stroke animate-pulse" />
                    <div className="h-3 mt-2 w-2/3 bg-stroke animate-pulse" />
                  </div>
                </div>

                <div className="mt-8 p-8 bg-white border border-stroke-primary rounded-lg">
                  <RetailCoIcon className="max-w-[106px]" />
                  <hr className="border-stroke-primary my-2" />
                  <div className="flex items-center justify-between">
                    <span className="text-content-tertiary">Amount</span>
                    <span className="bg-stroke-secondary rounded-md py-0.5 px-3 text-blacked">
                      85 Eur
                    </span>
                  </div>
                </div>

                <div className="flex gap-x-4 justify-between items-center">
                  <Button
                    title="Decline"
                    color="light"
                    size="custom"
                    className="mt-6 flex-1 text-red-alert bg-white2"
                    // onClick={() => navigate("/setup-declined")}
                  />
                  <Button
                    title="Approve"
                    color="dark"
                    size="custom"
                    className="mt-6 flex-1"
                    onClick={onApprove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentAuthorization;
