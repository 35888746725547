import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import { InputContainer } from "@components/input";
import RadioButton from "@components/RadioButton";
import SearchInput from "@components/searchInput";

// Assets
import { ReactComponent as EWLBankFlag } from "@icons/bank-ewl.svg";
import { ReactComponent as AxaBankFlag } from "@icons/bank-axa.svg";
import { ReactComponent as BnpParibasBankFlag } from "@icons/bank-bnp-paribas.svg";
import { ReactComponent as CaretIcon } from "@icons/arrow_down.svg";
import { ReactComponent as CreditAgricoleBankFlag } from "@icons/bank-credit-agricole.svg";
import { ReactComponent as CreditMutuelFlag } from "@icons/bank-credit-mutuel.svg";
import { ReactComponent as CreditDuNordFlag } from "@icons/bank-credit-du-nord.svg";
import { ReactComponent as FlagFrance } from "@icons/flag-fr.svg";
import { ReactComponent as GroupBPCEFlag } from "@icons/bank-bpce.svg";
import { ReactComponent as HSBCFlag } from "@icons/bank-hsbc-france.svg";
import { ReactComponent as LaBanquePostaleFlag } from "@icons/bank-la-bank-postale.svg";
import { ReactComponent as RevolutBankFlag } from "@icons/bank-revolut.svg";
import { DemoGuidePopoverContext } from "@context/demoGuidePopover";

const Authenticate = ({ setStep }) => {
  const navigate = useNavigate();
  const [currentBank, setCurrentBank] = useState("apl");
  const [query, setQuery] = useState("");
  const { setLoginPopUp } = useContext(DemoGuidePopoverContext);
  const banks = [
    {
      name: "Apollo Bank",
      value: "apl",
      flag: (
        <div className="min-w-[40px] lg:min-w-[52px] h-12 rounded text-2xl bg-tblue text-white flex items-center justify-center">
          A
        </div>
      ),
    },
    {
      name: "EWL Bank",
      value: "ewl",
      flag: <EWLBankFlag className="w-full" />,
    },
    {
      name: "Revolut",
      value: "revolut",
      flag: <RevolutBankFlag className="w-full" />,
    },
    {
      name: "BNP Paribas",
      value: "bnp",
      flag: <BnpParibasBankFlag className="w-full" />,
    },
    {
      name: "Crédit Agricole Group",
      value: "cag",
      flag: <CreditAgricoleBankFlag className="w-full" />,
    },
    {
      name: "AXA Banque",
      value: "axa",
      flag: <AxaBankFlag className="w-full" />,
    },
    {
      name: "Group BPCE",
      value: "bpce",
      flag: <GroupBPCEFlag className="w-full" />,
    },
    {
      name: "Crédit Mutuel Group",
      value: "cmg",
      flag: <CreditMutuelFlag className="w-full" />,
    },
    {
      name: "La Banque Postale",
      value: "lbp",
      flag: <LaBanquePostaleFlag className="w-full" />,
    },
    {
      name: "HSBC France",
      value: "hsbc",
      flag: <HSBCFlag className="w-full" />,
    },
    {
      name: "Crédit du Nord",
      value: "cdn",
      flag: <CreditDuNordFlag className="w-full" />,
    },
  ];
  const handleSearch = () => {
    return banks.filter(
      ({ name, value }) =>
        name.toLowerCase().includes(query) ||
        value.toLowerCase().includes(query)
    );
  };

  const topRef = useRef(0);
  useEffect(() => {
    topRef?.current?.scrollIntoView();
  }, []);

  return (
    <div className="pb-28" ref={topRef}>
      <InputContainer
        className="flex items-center justify-between"
        label="Select a Country"
      >
        <div className="flex items-center gap-x-2">
          <FlagFrance />
          <span>France</span>
        </div>
        <CaretIcon />
      </InputContainer>
      <p className="text-desc-grey text-base leading-5 font-semibold mt-4 lg:mt-6 mb-1">
        Select a Bank
      </p>
      <SearchInput
        handleChange={(query) => setQuery(query.toLowerCase())}
        className="py-3 border-b-0 rounded-t-lg bg-white"
      />
      <div className="flex flex-col divide-y-[1px] divide-grey-10 border border-stroke-primary rounded-b-lg p-0 bg-transparent">
        {handleSearch().map((bank) => (
          <div
            key={bank.value}
            className={getClassNames(
              "group flex justify-between items-center hover:bg-white2 py-2 px-4",
              bank.value !== "apl" && "opacity-50"
            )}
            onClick={() => {}}
          >
            <div className="flex items-center gap-x-4">
              <div className="max-w-[40px] lg:max-w-[52px] overflow-hidden">
                {bank.flag}
              </div>
              <span
                className={getClassNames(
                  "group-hover:text-body-black",
                  currentBank === bank.value && "text-body-black"
                )}
              >
                {bank.name}
              </span>
            </div>
            <RadioButton active={bank.value === currentBank} />
          </div>
        ))}
      </div>
      <div className="fixed w-ful -bottom-2 flex items-center gap-x-2 mt-6 py-5  w-1/2">
        <Button
          title="Cancel"
          category="1"
          color="light"
          className="w-fit"
          onClick={() => navigate("/setup-declined")}
        />
        {/* <a href="https://openbanking.mastercard.eu/sandbox/mock/index.html?state=3b908e5c-0a88-45aa-916e-ca6cf70997dd&request=eyJjb25zZW50UmVxdWVzdElkIjoiMTIzNDUiLCJ0cHBSZWRpcmVjdFVSSSI6Imh0dHBzOi8vZGVtbzIuY29uc3VtZXIudHJ1ZGVudHkuY29tL2F1dGhvcml6ZSJ9"> */}
        <Button
          title="Continue"
          category="1"
          color="dark"
          className="w-fit"
          disabled={!currentBank}
          onClick={() => {
            navigate("/bank-login");
            setLoginPopUp(true);
          }}
        />
        {/* </a> */}
      </div>
    </div>
  );
};

export default Authenticate;
