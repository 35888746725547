import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import cryptoRandomString from "crypto-random-string";
import base32 from "hi-base32";

// Utils
import { axiosInstance } from "@utils/baseAxios";
import { getClassNames } from "@utils/helpers";

// Contexts
import { authContext } from "@context/auth";

// Components
import { Button } from "@components/button";
import DownloadLinks from "./components/DownloadLinks";
import IncorrectInputError from "./components/IncorrectInputError.js";

const OtpHandler = ({ isEnrollment, setStep, setQrcodeLink }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [qrData, setQrData] = useState({});
  const splitSearch = search.split("&");
  const [otp, setOtp] = useState("");
  const [status, setStatus] = useState(0);
  const { email, setHasCookie } = useContext(authContext);
  const [enrollmentSecret, setEnrollmentSecret] = useState();

  useEffect(() => {
    if (!email) {
      setStep(0);
    }
  }, [email, setStep]);

  const generateSecret = async () => {
    const secret = await cryptoRandomString({
      length: 16,
      type: "distinguishable",
    });
    setEnrollmentSecret(base32.encode(secret).substring(0, 16));
  };
  useEffect(() => {
    if (isEnrollment) {
      generateSecret();
      if (splitSearch.length === 3) {
        setQrData({
          org: splitSearch[0].split("=")[1],
          email: splitSearch[1].split("=")[1],
          issuer: splitSearch[2].split("=")[1],
        });
      }
    }
  }, []);

  const onChangeHandler = (val) => {
    setOtp(val);
    setStatus(0);
  };

  const login = async () => {
    axiosInstance
      .post("/create_session", {
        email,
        otp,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((_response) => {
        if (!isEnrollment) {
          // setIsAuthenticated(true);
          setHasCookie(true)
          navigate("/dashboard");
        } else {
          localStorage.setItem(qrData.email, enrollmentSecret);
          setStep(2);
        }
      })
      .catch((_error) => {
        setStatus(2);
      });
  };

  const { handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setQrcodeLink(
      `otpauth://totp/${qrData.org}:${qrData.email}?issuer=${
        qrData.issuer
      }&secret=${"WC4KTAXDE2L2V4HX"}&algorithm=SHA1&digits=6&period=30`
    );
  }, [
    enrollmentSecret,
    qrData.email,
    qrData.issuer,
    qrData.org,
    setQrcodeLink,
  ]);

  return (
    <form onSubmit={handleSubmit(login)}>
      <OtpInput
        value={otp}
        onChange={onChangeHandler}
        numInputs={6}
        renderSeparator={(i) =>
          i === 2 && <span className="min-w-[24px] text-2xl text-white"></span>
        }
        renderInput={(props, i) => (
          <input
            {...props}
            className={getClassNames(
              "min-w-[3.6875rem] min-h-[4rem] text-2xl border-y border-stroke4 placeholder-placeholder-grey",
              "text-body-black selection:bg-none bg-grey-850  focus:outline-body-black focus:border focus:border-body-black focus:ring-0",
              i === 2 || i === 5 ? "border-x rounded-r" : "border-l",
              i === 0 || i === 3 ? "rounded-l" : ""
            )}
            placeholder="-"
          />
        )}
      />
      <IncorrectInputError show={status === 2} />

      <Button
        type="submit"
        title="Continue"
        color="dark"
        size="custom"
        className="mt-6 w-fit"
        disabled={otp.length < 6}
        onClick={login}
      />
      <DownloadLinks />
    </form>
  );
};

export default OtpHandler;
