import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-white.svg";
import { ReactComponent as TickActive } from "@icons/tick-active.svg";
import { ReactComponent as TickInactive } from "@icons/tick-inactive.svg";

const DemoGuidePopover = ({
  title,
  body,
  maxWidth = "max-w-[350px]",
  topOffset = 100,
  leftOffset = 100,
  arrowPosition = "top",
  open,
  noArrow = false,
  setOpen,
  el,
  children,
}) => {
  const [check, setCheck] = useState(false);
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });

  const email = localStorage.getItem("email");
  const isPopUpDisable = localStorage.getItem("popUpDisabled");

  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver(() => {
      updatePopoverPosition();
    });

    // Start observing the target element
    resizeObserver.observe(el());

    // Create a new MutationObserver instance
    const mutationObserver = new MutationObserver(() => {
      updatePopoverPosition();
    });

    // Start observing the target element for configured mutations
    mutationObserver.observe(el(), {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [el]);

  const disablePopup = () => {
    setOpen(false);
    if (check) {
      localStorage.setItem("popUpDisabled", String(true));
    }
  };

  // Function to update the popover's position
  function updatePopoverPosition() {
    const itop = el()?.getBoundingClientRect().top ?? 0;
    const ileft = el()?.getBoundingClientRect().left ?? 0;

    setCoordinates({ top: itop + topOffset, left: ileft + leftOffset });
  }

  const arrowPositions = {
    top: {
      top: "-5px",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
    },
    bottom: {
      bottom: "-5px",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
    },
    left: {
      left: "-5px",
      top: "50%",
      transform: "translateY(-50%) rotate(45deg)",
    },
    right: {
      right: "-5px",
      top: "50%",
      transform: "translateY(-50%) rotate(45deg)",
    },
  };

  return email && !isPopUpDisable ? (
    <Transition appear show={open} as={Fragment}>
      <div className="relative z-50">
        <div
          className="fixed overflow-y-aut"
          style={{ top: `${coordinates.top}px`, left: `${coordinates.left}px` }}
        >
          <div
            className={getClassNames(
              "absolute w-3 h-3 bg-body-black rounded-sm rotate-45",
              noArrow ? "hidden" : ""
            )}
            style={{ ...arrowPositions[arrowPosition] }}
          />
          <div className="relative flex container-solutions">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={getClassNames(
                  `w-full transform rounded-lg bg-body-black 
                  shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-6`,
                  maxWidth
                )}
              >
                <div className="flex items-center gap-x-2 justify-between">
                  <h6 className="header6 text-white">{title}</h6>
                  <CloseIcon
                    className="fill-white cursor-pointer"
                    onClick={() => disablePopup()}
                  />
                </div>
                <p className="text-grey-content-tertiary mt-3">{body}</p>
                <div>{children}</div>
                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center gap-x-1">
                    {check ? (
                      <TickActive
                        onClick={() => setCheck(false)}
                        className="cursor-pointer"
                      />
                    ) : (
                      <TickInactive
                        onClick={() => setCheck(true)}
                        className="cursor-pointer"
                      />
                    )}

                    <span className="text-grey-content-quaternary">
                      Don’t show again
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  ) : (
    <></>
  );
};

export default DemoGuidePopover;
